@import "./common-style/common-style.scss";

body,
#root {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
