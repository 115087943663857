@import "../../common-style/common-style.scss";

.mobile-page {
  display: flex;
  width: 100%;
  height: 100vh;
  .carousel-root {
    display: contents;
    flex: 1;
    .thumbs-wrapper {
      display: none;
    }
    .carousel-slider {
      flex: 1;
      background: #181818;
      display: grid;
      .control-dots {
        .dot {
          background: white;
          box-shadow: unset;
          opacity: 1;
          &.selected {
            background: $red;
          }
        }
      }
      .slider-wrapper {
        height: 100%;
      }

      .slider {
        height: 100%;
      }

      .card-slide {
        display: flex;
        flex-direction: column;
        height: 100%;
        .image-container {
          .image {
            height: 100%;
          }
        }
        .content {
          flex: 1;
          overflow: hidden;
          .title {
            @include poppins500(28px);
            color: white;
            padding: 0px 24px;
            text-align: center;
          }
          .slide-top-container {
            background-color: #212838;
            height: 22%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;
          }
          .slide-bottom-container {
            height: calc(70% - 10px);
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 16px 16px;
          }
          .text {
            @include poppins500(20px);
            color: white;
            padding: 0px 16px;
            text-align: left;
          }
          .empty-space {
            margin-bottom: 20px;
          }
          @media only screen and (max-width: 600px) {
            .title {
              font-size: 20px;
            }
            .text {
              font-size: 16px;
            }
          }
          @media only screen and (max-width: 400px) {
            .title {
              font-size: 18px;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
