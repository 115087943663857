@import "../../common-style/common-style.scss";

.extension-page {
  display: flex;
  width: 100%;
  height: 100vh;
  .carousel-root {
    display: contents;
    flex: 1;
    .thumbs-wrapper {
      display: none;
    }
    .carousel-slider {
      height: 100%;
      background: #181818;
      .control-dots {
        .dot {
          background: white;
          box-shadow: unset;
          opacity: 1;
          &.selected {
            background: $red;
          }
        }
      }
      .slider-wrapper {
        height: 100%;
      }
      .card-slide {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100vh;
        align-items: center;
        .image-container {
          height: 100%;
          .image {
            height: 100%;
          }
        }
        .content {
          flex: 1;
          height: 100vh;
          .title {
            @include poppins500(32px);
            color: white;
            padding: 0px 24px;
            text-align: center;
          }
          .slide-top-container {
            background-color: #212838;
            height: 22%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          .slide-bottom-container {
            height: calc(75% - 10px);
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
            padding: 16px 16px;
          }
          .text {
            @include poppins500(20px);
            color: white;
            padding: 0px 16px;
            text-align: left;
            &.extra-space-between-lines {
              line-height: 38px;
            }
          }

          .empty-space {
            margin-bottom: 20px;
          }
        }
      }
      @media only screen and (max-width: 840px) {
        .card-slide {
          flex-direction: column;
        }
        .image-container {
          position: absolute;
          opacity: 0.2;
          z-index: 0;
        }
        .content {
          z-index: 1;
        }
      }
      @media only screen and (min-width: 1400px) {
        .title {
          font-size: 36px !important;
        }
        .text {
          font-size: 24px !important;
        }
      }
    }
  }
}
